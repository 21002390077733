<template>
    <div class="d-flex flex-column text-center">
        <span class="text-h3 font-weight-bold mb-5">Sucesso!</span>
        <span class="text-h6 font-weight-light grey--text text--darken-2">
            Seu pedido está sendo análisado por nossa equipe.
        </span>
        <span class="text-h6 font-weight-light grey--text text--darken-2">
            Você pode acompanhar o processo em nossa plataforma!
        </span>
        <a
            href="http://localhost:8080/login"
            class="mb-5 text-h6 font-weight-light"
        >ir para plataforma</a>
        <img
            class="preco-svg"
            src="../assets/svg/order_confirmed.svg"
            alt=""
        >
    </div>
</template>
<script>
export default {
    name: 'AnalisePedido'
}
</script>

<style lang="scss" scoped>
img {
    width: 80%;
    max-width: 550px;
    align-self: center;
}
</style>
